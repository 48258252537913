<template>
	<footer id="TheFooter">
		<div class="logo-container">
			<img
				src="./telenor-logo-flat-blue.svg?url"
				alt="Telenor Logo"
				loading="lazy"
			/>
		</div>

		<ul class="category-container">
			<MenuCategoryList
				v-for="(category, index) in categories"
				:category-heading="category.categoryHeading"
				:menu-links="category.menuLinks"
				:use-two-columns="category.useTwoColumns"
				:key="`category-${index}`"
				:is-footer="true"
				:index="index"
				:color-scheme="'black'"
			/>
		</ul>

		<div class="social-media-container">
			<img
				src="./telenor_logotype_black.svg?url"
				alt="Telenor Logo Typed"
				width="112"
				height="50"
				loading="lazy"
			/>

			<div class="icon-container">
				<template v-for="(social, index) in socialMediaList">
					<TnButton
						v-if="social.icon"
						class="social-media-button"
						:key="social.icon"
						v-bind="social.link"
						:iconOnly="social.icon"
						tertiary
						dark
					/>
					<span
						v-else
						class="link-container"
						:key="index"
					>
						<component
							:is="social.link.href ? 'a' : 'NuxtLink'"
							:href="social.link.href"
							:to="social.link.to"
						>
							<img
								:src="social.image.src"
								:alt="social.image.alt"
								loading="lazy"
							/>
						</component>
					</span>
				</template>
			</div>
		</div>

		<TnDivider
			dark
			width="full"
			size="m"
		/>

		<div class="copyright-container">
			<img
				src="https://www.telenor.no/binaries/frontend/telefonkiosk.svg?url"
				alt="Telenor telefonkiosk"
				width="28"
				height="50"
				class="kiosk"
				loading="lazy"
			/>
			<span> &copy; 1855-{{ lastYear }} Telenor ASA </span>
		</div>
	</footer>
</template>

<script>
import contentService from "~/integrations/content-service-integration";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";
import { mapState } from "vuex";
import GizmoHeaderViewModel from "~/components/platform/globals/TheHeader/GizmoHeaderViewModel.js";

export default defineNuxtComponent({
	name: "TheFooter",

	computed: {
		...mapState(["siteSegment"]),
		socialMediaList() {
			if (this.footer?.content?.socialMedia && this.footer?.content?.socialMedia?.length > 0) {
				return (
					this.footer?.content?.socialMedia.map((socialMedia) => ({
						...socialMedia,
						link: LinkViewModel(socialMedia.link),
					})) || []
				);
			} else {
				return (
					this.footer?.content?.socialMediaList?.map((socialMedia) => ({
						...socialMedia,
						link: LinkViewModel(socialMedia.link),
					})) || []
				);
			}
		},
		footer() {
			let fromState = this.$store.state.globals[this.siteSegment].footer;
			fromState = JSON.parse(JSON.stringify(fromState));
			return fromState;
		},
		lastYear() {
			return new Date().getFullYear();
		},
		categories() {
			return (
				this.footer?.content?.footerBottomCategory?.map((category) => ({
					...category,
					categoryHeading:
						typeof category.categoryHeading === "string" || category.categoryHeading.text
							? category.categoryHeading.text || category.categoryHeading || ""
							: LinkViewModel(category.categoryHeading.link),
					menuLinks: [
						...(category.textNormal ? category.textNormal : []),
						...(category.textLink ? category.textLink : []),
						...(category.textNormalBottom ? category.textNormalBottom : []),
					].map((item) => {
						if (typeof item === "string") {
							return { text: item };
						} else {
							return LinkViewModel(item) || [];
						}
					}),
				})) || []
			);
		},
	},
});
</script>

<style lang="scss" scoped>
footer {
	padding: $spacing-3xl $spacing-xl;
	background: $color-neutrals-black;
	color: $color-neutrals-white;
	font-family: $font-family-telenor-ui;

	:deep(.dropdown-header > a) {
		@include font-text-l;
	}

	:deep(.dropdown-category .dropdown-link-container) {
		margin-top: $spacing-l;

		@include font-text-m;

		@include breakpoint(mobile) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	:deep(#tn-chat) {
		height: auto;
	}

	:deep(#tn-chat .tn-chat) {
		color: $color-text;
	}

	:deep(.dropdown-link-container li + li) {
		margin-top: $spacing-m;
	}

	.logo-container {
		max-width: 1190px;
		margin: 0 auto;
		padding-bottom: $spacing-3xl;

		:deep(img) {
			width: 44px;
			height: 40px;
		}

		@include breakpoint(mobile) {
			padding-bottom: $spacing-xl;
		}
	}

	:deep(ul.category-container) {
		max-width: 1190px;
		margin: 0 auto;
		list-style: none;
		padding: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.social-media-container {
		display: flex;
		justify-content: space-between;
		max-width: 1190px;
		margin: $spacing-4xl auto 0;

		.icon-container {
			line-height: 0;

			.social-media-button {
				--button-foreground-color: $color-neutrals-100-tint;

				margin-left: $spacing-2xl;
			}

			.link-container {
				display: inline-block;
				margin-left: $spacing-2xl;
				padding: $spacing-xs;

				:deep(img) {
					height: 20px;
				}
			}
		}

		@include breakpoint(mobile) {
			margin-top: $spacing-3xl;
		}
	}

	.copyright-container {
		max-width: 1190px;
		margin: 0 auto;
		opacity: 0.5;

		@include font-text-xs;

		.kiosk {
			display: inline-block;
			vertical-align: bottom;

			:deep(img) {
				width: 16px;
				height: 32px;
			}
		}

		span {
			margin-left: $spacing-xs;
		}
	}

	@media (max-width: $size-screen-s) {
		.social-media-container {
			display: block;

			.icon-container {
				display: flex;
				justify-content: space-between;
				margin-top: $spacing-2xl;

				.social-media-button {
					margin-left: 0;
				}

				.link-container {
					margin-left: 0;
					min-width: 28px;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		padding: $spacing-xl $spacing-xl;
	}
}
</style>
